.main-logo {
    position:absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
}

.front-contact-info {
    background-color: #d2d2d2;
    font-size: 0.9em;
    font-weight: 600;

    .red-stripe {
        border-bottom: 2px solid red;
        width: 50px;
        margin: 20px 0px;
    }
}

.front-contact-row {
    &>div {
        height: 200px;
    }
}

.home-slider-dots {
    position: absolute;
    left: 0%;
    bottom: 7%;
    z-index: 1000;
    width: 100%;
}