$banner-height: 45px;
$image-skew: -18deg;
$banner-font-size: 1.3em;

$min-banner-height: 30px;
$min-banner-right-offset: 50px;
$min-banner-font-size: 1em;

.BottomBanner {
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: $banner-height;
    background-color: #000;
    width: 100%;

    @include transition(height 0.3s linear);


    &__textContainer {

        position: absolute;
        margin-left: 20px;
        margin-top: 8px;
        left: 0px;
        right: 313px;
        height: $banner-height;
        color: white;
        text-transform: uppercase;
        font-size: $banner-font-size;
        font-style: italic;
        white-space:nowrap;
        overflow:hidden;


        @include transition(height 0.3s linear);
        @include transition(font-size 0.3s linear);


        .BottomBanner__text {

            position: relative;
            margin: 0 auto;
            overflow: hidden;
            white-space: nowrap;
            box-sizing: border-box;
            display:inline;
            width:auto;

        }

        .hl {
            color: $red;
            font-weight: 600;
        }

    }

    &__imageContainer {
        position: absolute;
        bottom: 0px;
        right: 100px;
        border: 2px solid $red;
        height: 170px;
        width: 200px;
        background-color: $red;
        transform: skew($image-skew);
        border-radius: 0px 0px 70px 0px;
    }

    &__imageBackground {
        background-color: white;
        height: 90px;
        margin: 5px;
        overflow: hidden;
        text-align: center;
        padding: 3px;
    }

    &__image {
        transform: skew($image-skew * -1);
        height: 100%;
    }
    &__imageText {
        color: white;
        font-weight: 600;
        font-size: 2.2em;
        transform: skew($image-skew * -1);
        padding: 0px 15px;
        font-style: italic;
    }

    &__minimizeButton {
        color: white;
        height: 100%;
        position: absolute;
        right: 0px;
        cursor: pointer;
    }

    &.BottomBanner--minimized {

        height: $min-banner-height;

        .BottomBanner__textContainer {
            height: $min-banner-height;
            right: $min-banner-right-offset;
            font-size: $min-banner-font-size;
            margin-top: 4px;
        }


    }

    @include respond-to(xs) {
        height: $min-banner-height;

        .BottomBanner__textContainer {
            height: $min-banner-height;
            right: 20px;
            font-size: $min-banner-font-size;
            margin-top: 4px;
        }

        .BottomBanner__minimizeButton, .BottomBanner__imageContainer {
            display: none !important;

        }
    }

}
