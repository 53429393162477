.language-switcher {
    position: relative;


    li {
        display: inline-block;

        a {

        }
    }
}


@media (min-width: 768px) and (max-width: 890px){
    .language-li {
        width: 70px;
    }
    .language-switcher {
        width: 150px;
        left: -52px;
        top: 10px;

        li {
            height: 12px;
            a {
                display: inline-block;
                font-size: 12px;
                height: 12px;
                padding: 0px 0px;
            }
        }
    }
}

@media (min-width: 891px) and (max-width: 991px){
    .language-switcher {
        left: 0px;
        top: 12px;
        li a {
            padding: 3px 6px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .language-switcher {
        left: 50px;
        top: 12px;
        li a {
            padding: 3px 6px;
        }
    }
}



@media (min-width: 1200px){
    .language-switcher {
        left: 138px;
        top: 12px;
        li a {
            padding: 3px 6px;
        }
    }
}


@media (min-width: 768px) {
    .navbar {
        height: 50px;
        margin-top: 20px;

        .navbar-nav {
            display: inline-block;
            float: none;

            font-family: "AquawaxLight",sans-serif;
            font-size: 17px;
            font-weight: 400;
        }

        .navbar-collapse {
            text-align: center;



            &>ul {
                position: relative;
                //left: -5.33%;
                top: -4px;

                &>li {
                    &>a {


                    }
                }
            }
        }

    }


}



.navbar {

    &.navbar-white-text {
        .navbar-collapse>ul>li>a {
            text-shadow: 1px 1px 1px black;

        }
    }
    .navbar-collapse {

        @include respond-to(768px) {
            background-color: black;
        }
        &>ul>li>a {
            @include respond-to(lg) {
                padding: 15px 25px;
            }

            @include respond-to(md) {
                padding: 15px 20px;
            }

            @include respond-to(sm) {
                padding: 15px 10px;
            }

            @include respond-to(792px) {
                padding: 15px 8px;
            }
            @include respond-to(768px) {
                color: white;

            }

            &:hover {
                @include respond-to(768px) {
                    color: white;

                }
            }
        }


    }
}
