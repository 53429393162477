@font-face {
    font-family: 'AquawaxLight';
    src: url('/fonts/AquawaxLight.ttf');
}

@font-face {
    font-family: 'AquawaxMedium';
    src: url('/fonts/AquawaxMedium.ttf');
}

@media (max-width: 767px) {
    body > .container { overflow: hidden; } /* fix */


}


root {
    display: block;

}

html {
    height: 100%;
    width:100%;

}

body {
    width:100%;
    height: 100%;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1.6em;
    background-image: url(/img/pages/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px;
    background-color: #fff;
}

.container-full {

}


.save-buttons {
    border-top: 1px dotted gray;
    padding-top: 10px;
    margin-bottom: 50px;
    margin-top: 30px;
}

.float-right {
    float: right;
}

.tabledit-danger {
    background-color: #f00 !important;
}

.tabledit-warning {
    background-color: rgba(0, 171, 17, 0.71) !important;

}

.delete-customer-button {
    cursor: pointer;
    color: red;
    padding: 0px 5px;
}

.delete-employee-button {
    cursor: pointer;
    color: red;
    padding: 0px 5px;
}
#customer-list {
    font-family: arial;
    font-size: 0.9em;
}

#employee-list {
    font-family: arial;
    font-size: 0.9em;
}

.sub-text {
    font-size: 0.7em;
}

.no-top-margin {
    margin-top: 0px !important;
}

.page-content {

    max-height: 100%;
    width: 100vw;
    max-width:100%;
    top:0;
    left:0;




    &.cover-bg {
        height: 100%;


    }

    &.full-bg {
        min-height: calc(100vh - 50px);

    }

    &.fixed-bg {
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
        min-height: 100vh;
    }


}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;

}

.spacer {
    height: 50px;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.opacity-hidden {
    opacity: 0;
}

.spacer {
    height: 50px;
}

.white-bg {
    background-color: #fff;
}

.text-box-with-virgule {
    position: relative;
    padding: 65px 15px 80px 15px;
    min-height: 50px;
}

.virgule {
    position: absolute;
    left: 65px;
    top: -26px;

    @include respond-to(1200px) {
        top: -20px;
        width: 85px;
    }


}

.stripe-bg {
    background-color: #ffffff;
    background-image: url(/img/pages/stripe_texture.png);
}

.navbar .divider-vertical {
    height: 50px;
    margin: 3px 9px 0px 9px;
    border-right: 2px solid #424242;
}

.navbar-inverse .divider-vertical {
    border-right-color: #222222;
    border-left-color: #111111;
}

@media (max-width: 767px) {
    .navbar-collapse .nav > .divider-vertical {
        display: none;
    }
}

.navbar .divider-horizontal {
    width: 95%;
    margin: 3px 1px 5px 15px;
    border-top: 2px solid #424242;
}

.navbar-inverse .divider-horizontal {
    border-right-color: #222222;
    border-left-color: #111111;
}

@media (min-width: 767px) {
    .navbar-collapse .nav > .divider-horizontal {
        display: none;
    }
}

.spacer {
    position: relative;
    height: 50px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.back-button {
    background-color: transparent;
    background-size: cover;
    padding: 10px 38px;
    font-size: 1.3em;
    float: right;
    color: black;
}