.ImageBox {
    overflow: hidden;
    //display: flex;
    //align-items: center;
    //justify-content: center;

    &__img {
        width: 100%;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    &__textWrapper {
        display: flex;
        position: absolute;
        width: 100%;

        height: 100%;
        top: 0px;
        align-items: center;
        justify-content: center;
    }

    &__text {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        color: white;
        font-size: 2em;
        max-width: 90%;
        text-align: center;

        @include respond-to(md) {
            font-size: 1.8em;
        }

        @include respond-to(xs) {
            font-size: 1.4em;
        }

    }

    &__stripe {
        border-bottom: 2px solid red;
        width: 30%;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

    }

    &:hover {
        .ImageBox__img {
            transform: scale(1.2);
        }
        .ImageBox__text {
            transform: scale(1.1);
        }

        .ImageBox__stripe {
            width: 100%;
        }
    }
}

.Titlebox {
    &__title {

        background-color: transparent;
        background-size: cover;
        display: inline-block;
        padding: 10px 30px 10px 15px;
        font-size: 1.4em;
        font-weight: 300;
    }

    &__content {

        border-top: 2px solid rgba(0, 0, 0, 0.30);
        color: white;
        position: relative;
        padding-bottom: 10px;
    }

    &--no-stripe {
        &>.Titlebox__content {
            border-top: 0px !important;
        }
    }

    &--small-title {
        &>.Titlebox__title {
            font-size: 1.1em;
        }
    }

    &--smaller-title {
        &>.Titlebox__title {
            font-size: 1em;
            padding: 5px 25px 5px 10px;
        }
    }
}