@media (max-width: 992px) {
    .padded-container {
        padding-left: 0px !important;
    }


}


@media (min-width: 992px) {
    .padded-container {
        padding-left: 50px !important;
    }
}


.padded-container {
    padding-left: 100px;
    font-weight: 600;


    .heading {
        margin-top: 30px;
    }
}

.other-page {
    .title {
        margin-left: -50px;
        h1 {

            font-weight: 600;
            margin-bottom: 5px;
            margin-top: -6px;
            font-size: 1.9em;
        }

        @include respond-to(md) { margin-left: 0px; }
        @include respond-to(sm) { margin-left: 0px; }
        @include respond-to(xs) { margin-left: 0px; }
    }

    .subtitle h2 {
        font-weight: 400;
        color: $red;
        font-size: 1.3em;

        margin: 5px 0px;
    }

    .gallery-spacer {
        margin-top: 30px;
    }
}

.page-body {
    margin-top: 30px;

    h3 {
        color: $red;
        font-weight: 400;
        margin-top: 0px;


    }
}

.red-divider {
    width: 20%;
    height:1px;
    border: 1px solid $red;
    background-color: $red;
    margin-top: 13px;
    margin-bottom: -10px;
}

.Partner {

    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;

    &__logo {
        width: 100%;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: none repeat scroll 0 0 rgb(132, 134, 135);
        opacity: 0;
        -webkit-transition: visibility 0.3s, opacity 0.3s linear;
        transition: visibility 0.3s, opacity 0.3s linear;
        visibility: hidden;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &:hover &__overlay {

        visibility: visible;
        opacity: 1;
    }
}

.Partner-OverlayLink {
    position: relative;

    display: flex;
    justify-content: left;
    align-self: center;

    &__icon {

        display: inline-block;


        img {
            width: 50px;
        }

    }

    &__text {
        padding-left: 20px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.1em;
        color: white;

        ul {
            padding-left: 20px;
        }
    }
}

#map-div {
    width: 100%;
    height: 200px;

    &.large {
        height: 400px;
    }
}



.Contact {
    &__title {
        font-weight: 600;
        color: $red;
        font-size: 1.2em;

    }

    &>div {
        padding-left: 20px;
        position: relative;
        margin-bottom: 5px;
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.eshop-frame {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
}

.menu-margin {
    margin-top: 90px;
}

.uni-main-logo {
    position: relative;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
}

.artist-categories {
    list-style: none;
    text-align: center;

    &>li {
        display: inline-block;
        text-align: center;
        margin: 0 25px 15px;
        padding-bottom: 3px;
        background-color: transparent;
        cursor: pointer;
        font-size: 1.1em;

        border-radius: 2px;
        color: black;
        @include prefixed(transition, all 0.3s);

        &:hover {
            box-shadow: 0 0 10px #e9edd3;
            color: black;
        }

        &.active {

        }
        a {
            color: inherit;
            display: block;
            padding: 10px 18px;
            cursor: pointer;

            &:link {
                text-decoration: inherit;
                color: inherit;
                cursor: pointer;
            }

            &:visited {
                text-decoration: inherit;
                color: inherit;
                cursor: pointer;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.artist-categories {
    .mixitup-control-active:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        background: red;
        height: 3px;

    }
}

.artist-categories>li.artist-top {
    display: block;
    font-weight: 600;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 15px;
}



.contact-form-container {
    margin-top: 15px;
    label {
        font-size: 1em;
        font-weight: normal;
    }

    input[type=text],input[type=email] {
        background-color: #f0f0f0;
        width: 100% !important;
    }

    input[type=submit] {
        border-radius: 3px;

    }

    .form-control {
        height: 30px;
        padding: 7px;
        line-height: 1.1;

    }
    .textarea {
        height: 100px;
        width: 100%;
        background-color: #f0f0f0;
    }

    .form-group {
        margin-bottom: 0px;
    }
}

.contact-info {

    font-weight: 600;

    .red-stripe {
        border-bottom: 2px solid red;
        width: 50px;
        margin: 20px 0px;
    }
}

.send-button {
    -moz-box-shadow:inset 0px 39px 0px -24px #08afc2;
    -webkit-box-shadow:inset 0px 39px 0px -24px #08afc2;
    box-shadow:inset 0px 39px 0px -24px #08afc2;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #00a0b5), color-stop(1, #00a3b5));
    background:-moz-linear-gradient(top, #00a0b5 5%, #00a3b5 100%);
    background:-webkit-linear-gradient(top, #00a0b5 5%, #00a3b5 100%);
    background:-o-linear-gradient(top, #00a0b5 5%, #00a3b5 100%);
    background:-ms-linear-gradient(top, #00a0b5 5%, #00a3b5 100%);
    background:linear-gradient(to bottom, #00a0b5 5%, #00a3b5 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a0b5', endColorstr='#00a3b5',GradientType=0);
    background-color:#00a0b5;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    border:1px solid #ffffff;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:15px;
    font-weight:bold;
    padding:10px 15px;
    text-decoration:none;
    text-shadow:0px 1px 0px #b23e35;
}
.send-button:hover {
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #00a3b5), color-stop(1, #00a0b5));
    background:-moz-linear-gradient(top, #00a3b5 5%, #00a0b5 100%);
    background:-webkit-linear-gradient(top, #00a3b5 5%, #00a0b5 100%);
    background:-o-linear-gradient(top, #00a3b5 5%, #00a0b5 100%);
    background:-ms-linear-gradient(top, #00a3b5 5%, #00a0b5 100%);
    background:linear-gradient(to bottom, #00a3b5 5%, #00a0b5 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a3b5', endColorstr='#00a0b5',GradientType=0);
    background-color:#00a3b5;
}
.send-button:active {
    position:relative;
    top:1px;
}

.bold-text {
    font-weight: 400;
}

