.gallery-box {
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;
}

.gallery-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 1px;
    cursor: pointer;

    .video-overlay {

        transition: transform 0.4s ease 0s;
    }



    img {
        display: block;
        position: relative;
        transition: transform 0.4s ease 0s;
        width: 100%;
        cursor: pointer;
    }

    &:hover {

        img {

        }

        .gallery-overlay {
            background: none repeat scroll 0 0 rgba(0, 0, 0, 0.70);


            opacity: 1;


        }

        .video-overlay {
            transform: scale(1.15);
        }
    }


}

.gallery-overlay,.gallery-item-text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    color: white;
    font-weight: 400;
    font-size: 1.1em;

    text-align: center;
    vertical-align: middle;
    top: 71%;
    padding-top: 4%;

    background: rgba(0, 0, 0, 0);
    @include prefixed(transition, background-color 0.3s ease);
    @include prefixed(transition, opacity 0.3s ease);



}

.gallery-detail-box {
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;

    .gallery-item-wrapper {
        padding: 1px;
    }
}


.gallery-item-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;

    img {
        display: block;
        position: relative;
        transition: transform 0.4s ease 0s;
        width: 100%;
    }

    &:hover {

        img {

            transform: scale(1.15);
        }

        .gallery-item-overlay {
            background: none repeat scroll 0 0 rgba(20, 22, 23, 0.2);

            &:before {
                opacity: 1;
                transform: scale(1.0);
            }
        }

        .gallery-item-text-overlay {
            background: none repeat scroll 0 0 rgba(0, 0, 0, 0.70);


            opacity: 1;
        }
    }
}

.image-desc {
    font-weight: normal;
    font-size: 0.8em;
}

.gallery-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: rgba(0, 0, 0, 0);
    @include prefixed(transition, background-color 0.3s ease);

    &:before {
        display: block;

        width: 46px;
        height: 46px;

        position: absolute;
        top: 50%;
        left: 50%;

        margin: -22px 0 0 -25px;

        border-radius: 5%;
        background: black;

        text-align: center;
        font: 14px/46px 'FontAwesome';
        color: #fff;

        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0;

        -webkit-transition: -webkit-transform 0.3s ease, opacity 0.3s ease;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

}